import React from "react";
import { Home } from "./widgets/home";
import { Product } from "./widgets/product";
import { Checkout } from "./widgets/checkout";

type AppProps = {
  widgetType: string;
};

export const App: React.FC<AppProps> = ({ widgetType }) => {
  switch (widgetType) {
    case "home":
      return <Home />;
    case "product":
      return <Product />;
    case "checkout":
      return <Checkout />;
    default:
      return null;
  }
};
