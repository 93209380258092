import { render } from "react-dom";
import { App } from "./index";

const elements = document.querySelectorAll("[data-widget]");

// @ts-ignore
for (const element of elements) {
  const { widgetType } = element.dataset;

  render(<App widgetType={widgetType} />, element);
}
