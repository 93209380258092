import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SearchHit } from "./Autocomplete";

export const useSelectedItem = (): [
  SearchHit,
  Dispatch<SetStateAction<SearchHit>>
] => {
  const [selected, setSelected] = useState<SearchHit>(null);

  useEffect(() => {
    if (selected) {
      const event = new CustomEvent("recycleUpdated", {
        detail: {
          name: selected.full_name,
          price: selected.top_price,
          url: `https://www.clove-recycling.co.uk/sell/${selected.manufacturer_slug}/${selected.slug}?mode=top`,
        },
      });
      document.dispatchEvent(event);
    }
  }, [selected]);

  return [selected, setSelected];
};
