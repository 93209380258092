import Autocomplete from "../components/Autocomplete";
import { useSelectedItem } from "../components/useSelectedItem";

export const Checkout = () => {
  const [selected, setSelected] = useSelectedItem();

  return (
    <div className="bg-black text-white p-6 text-center">
      <h1 className="text-xl">
        Find Out How Much Your Existing Phone is Worth
      </h1>
      <div className="sm:flex py-2">
        <Autocomplete onSelected={setSelected} />
        <h2 className="flex-1">You could get: {selected?.top_price ? `£${selected.top_price}` : ''}</h2>
      </div>
      <input type="checkbox" id="followUp" />
      <label htmlFor="followUp">
        Do not send me a follow up email about recycling my old phone (uses
        email from checkout)
      </label>
    </div>
  );
};
