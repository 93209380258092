import Autocomplete from "../components/Autocomplete";
import { useSelectedItem } from "../components/useSelectedItem";

export const Product = () => {
  const [selected, setSelected] = useSelectedItem();

  return (
    <div className="bg-black text-white p-6 text-center">
      <h1 className="text-xl">Trade In Your Existing Phone</h1>
      <div className="sm:flex py-2">
        <Autocomplete onSelected={setSelected} />
        <h2 className="flex-1">You could get: {selected?.top_price ? `£${selected.top_price}` : ''}</h2>
      </div>
      {selected && (
        <a
          href={`https://www.clove-recycling.co.uk/sell/${selected.manufacturer_slug}/${selected.slug}?mode=top`}
          className="inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium shadow-sm sm:text-sm bg-gray-800 hover:bg-gray-700 focus:ring-gray-500 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
        >
          Sell Us Your Phone
        </a>
      )}
    </div>
  );
};
