import Autocomplete from "../components/Autocomplete";

export const Home = () => {
  const navigate = (selected) => {
    window.location.href = `https://www.clove-recycling.co.uk/sell/${selected.manufacturer_slug}/${selected.slug}?mode=top`;
  };

  return (
    <div className="bg-black text-white p-6 text-center">
      <h1>How Much Is My Phone Worth?</h1>
      <div className="w-full">
        <Autocomplete onSelected={navigate} />
      </div>
    </div>
  );
};
